window.esignContact = window.esignContact || {};

esignContact.init = function () {
  const ibanInput = document.getElementById("iban");
  if(ibanInput){
      ibanInput.addEventListener("keypress", function(event) {
          if (event.key === " ") {
            event.preventDefault();
          }
      });
  }

  if(!Modernizr.touch) {
    $('.validate').validationEngine();
  } else {
    $('.validate').validationEngine({scroll: false});
  }

  esignContact.formAjax();
};

/* Captcha */
esignContact.formAjax = function () {
  $('.form-ajax-recaptcha').submit(function(e) {
    e.preventDefault();
    var $form = $(this);


    if($form.hasClass('validate')){
      if ($form.validationEngine('validate') == false) {
        return false;
      }
    }

    if($form.find('[name="g-recaptcha-response"]').length && $form.find('[name="g-recaptcha-response"]').val() != ''){
      grecaptcha.reset();
    }

    grecaptcha.execute($form.find('.g-recaptcha').data('widgetid'));

    return false;
  });
};

/**
 * async ReCaptcha inladen en toevegen aan elk recaptcha blokje
 */
var onloadReCaptchaCallback = function() {
  $('.g-recaptcha').each(function(i,el){
    var attributes = {
      'sitekey'  : $(el).data('sitekey'),
      'size'     : $(el).data('size'),
      'callback' : $(el).data('callback')
    };

    var widgetid = grecaptcha.render(el, attributes);
    $(el).data('widgetid',widgetid);
  });
};

function onSubmitContact(token) {
  var $form = $('#contactForm');

  esignContact.recaptchaFormSend($form);
};





esignContact.recaptchaFormSend = function(form){

  $form = $(form);
  $form.find('input[type="submit"], button').attr('disabled', 'disabled');

  form_data = $form.serializeArray();

  $.post($form.attr('action'), form_data, function (data) {

    $('li').removeClass('error');
    var $result = $form.find('.result');

    if (data.errors === false) {
      $form.html('<div class="note note--success">' + data.result + '</div>');

      if ($form.data('title') !== '' && $form.data('label') !== '') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmission',
          'formTitle': $form.data('title'),
          'formLabel': $form.data('label')
        });

        function uet_report_conversion() {window.uetq = window.uetq || [];window.uetq.push('event', 'formSubmission', {});}
        uet_report_conversion();
      }

      if(typeof data.redirect !== "undefined"){
        window.location = data.redirect;
      }

    } else {
      $result.html('<div class="note note--error">' + data.result + '</div>');
      if (data.fields) {
        $.each(data.fields, function (i, field) {
          $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
        });
      }
    }

    $form.find('input[type="submit"], button').removeAttr('disabled');

  }).fail(function(response) {
    alert('Error: ' + response.responseText);
  });

};


// initialize on docready
$(esignContact.init);
